@media screen and (max-width: 575px) {
  .download-section-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 2%;
    margin-top: 2%;
  }
}
@media screen and (min-width: 576px) {
  .download-section-3 {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 2%;
  }
}
@media screen and (min-width: 768px) {
  .download-section-3 {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 2%;
  }
}
@media screen and (min-width: 992px) {
  .download-section-3 {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 2%;
  }
}
@media screen and (min-width: 1200px) {
  .download-section-3 {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 2%;
  }
}
