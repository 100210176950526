@import url("https://fonts.googleapis.com/css?family=Comfortaa");

.hw {
  height: 100% !important;
  width: 100% !important;
}

.body {
  font-family: "Comfortaa", normal !important;
}

.container {
  max-width: initial !important;
}

.model-bg {
  background-color: #0388db;
  color: #fff;
}

.model-msg-bg {
  background-color: #fb7a03;
  color: #fff;
}

.orange-color {
  color: #fb7a03 !important;
}

.blue-color {
  color: #0388db !important;
}

.white-color {
  color: #fff;
}

.header-text-color {
  color: #0388db !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.home-text-1 {
  color: #0388db;
  font-weight: bold;
}

.home-text-2 {
  color: #fb7a03;
  font-weight: bold;
}

.download-text-1 {
  color: #fb7a03;
  font-weight: bold;
}

.download-text-2 {
  color: #0388db;
  font-weight: bold;
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.text-align-left {
  text-align: left;
}

.set-margin {
  margin-top: 60px !important;
}

.MV-set-margin {
  margin-top: 40px !important;
}
.set-margin-container {
  margin-top: 30px;
}

.Home-Container {
  padding-bottom: 50px;
  padding-top: 4%;
}

.home-download-button {
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  background-color: #fff !important;
  border-color: #fb7a03 !important;
  text-align: center;
  color: #fb7a03 !important;
  border-width: 0.1cm !important;
}

.donate-button {
  padding-left: 5% !important;
  padding-right: 5% !important;
  background-color: #fff !important;
  border-color: #fb7a03 !important;
  text-align: center;
  color: #fb7a03 !important;
  border-width: 0.1cm !important;
}

.about-download-button {
  padding-left: 2% !important;
  padding-right: 2% !important;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
  background-color: #fff !important;
  border-color: #fb7a03 !important;
  text-align: center;
  color: #fb7a03 !important;
  border-width: 0.1cm !important;
}

.home2-download-button {
  padding-left: 2% !important;
  padding-right: 2% !important;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
  background-color: #ffff !important;
  border-color: #ffff !important;
  text-align: center;
  color: #fb7a03 !important;
  margin-top: 5%;
}

.home2-container-style {
  padding-top: 4%;
  background-color: #fb7a03 !important;
  color: #ffff !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin-bottom: 50px; */
  padding-bottom: 80px;
}

.home3-container-style {
  padding-top: 8%;
  color: #fb7a03 !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.home4-container-style {
  color: #fb7a03 !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin-bottom: 100px; */
  padding-bottom: 80px;
}

.download2-container-style {
  background-color: #0388db !important;
  color: #ffff !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.home-image2 {
  float: right;
  width: 40%;
  margin-left: 20px;
}

.mission-image {
  float: right;
  height: 30%;
  width: 40%;
  margin-left: 20px;
}

.home2-container-title {
  text-align: left;
  font-weight: bold;
  margin-top: 2%;
}

.home3-container-title {
  text-align: left;
  font-weight: bold;
}

.home4-container-title {
  text-align: left;
  margin-top: 8%;
  font-weight: bold;
}

.member1-container-title {
  text-align: left;
  padding-top: 80px !important;
  font-weight: bold;
}

.download1-container-title {
  text-align: left;
  padding-top: 80px !important;
  font-weight: bold;
}

.member-container1-style {
  padding-top: 4%;
  background-color: #0388db !important;
  color: #ffff !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin-bottom: 50px; */
  padding-bottom: 80px;
}

.download1-container-style {
  padding-top: 4%;
  color: #fb7a03 !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 50px;
}

.signup-container1-style {
  background-color: #fb7a03 !important;
  color: #ffff !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 80px;
  padding-top: 50px;
}

.signup-text-container {
  line-height: 1.7;
  margin-top: 8%;
}

.contact-container1-style {
  padding-top: 30px;
  background-color: #0388db !important;
  color: #ffff !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-bottom: 20px;
}

.enroll-button {
  padding-left: 9% !important;
  padding-right: 9% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  background-color: #ffff !important;
  border-color: #ffff !important;
  text-align: center;
  color: #0388db !important;
  margin-top: 1%;
}

.signup-button {
  padding-left: 9% !important;
  padding-right: 9% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
  background-color: #ffff !important;
  border-color: #ffff !important;
  text-align: center;
  color: #fb7a03 !important;
  margin-top: 1%;
}

.error-style {
  color: #ff0000;
  font-weight: bold;
}

.nav-image {
  height: 120px;
  width: 220px;
}

.mt-0-3-rem {
  margin-top: 0.3rem;
}

.mt-20-per {
  margin-top: 20%;
}

.mb-20-per {
  margin-bottom: 20%;
}

.rem1-text-size {
  font-size: 1rem;
}

.rem1-5-text-size {
  font-size: 1.5rem;
}

.rem2-text-size {
  font-size: 2rem;
}

.rem2-5-text-size {
  font-size: 1.5rem !important;
}

.rem2--5-text-size {
  font-size: 2.5rem !important;
}

.rem3-text-size {
  font-size: 3rem !important;
}

.rem3-3-text-size {
  font-size: 3.3rem !important;
}

.rem3-4-text-size {
  font-size: 3.4rem !important;
}

.rem3-6-text-size {
  font-size: 3.6rem !important;
}

.rem5-2-text-size {
  font-size: 5.2rem !important;
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: bold;
}

.height-line {
  line-height: 1.8em;
}
.float-none {
  float: none !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.checkbox-hw {
  height: 20px;
  width: 20px;
}

.terms-checkbox-hw {
  height: 15px;
  width: 15px;
}

.checkbox-font {
  margin-left: 20px;
}

.height-100-per {
  height: 100% !important;
}

.width-100-per {
  width: 100% !important;
}

.width-50-per {
  width: 50% !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-3-per {
  margin-left: 3%;
}

.ml-4-per {
  margin-left: 4%;
}

.ml-5-per {
  margin-left: 5%;
}

.ml-6-per {
  margin-left: 6%;
}

a:hover {
  color: #ffb984 !important;
}

@media (min-width: 1024px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  }
}

@media screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.4rem;
  }
}

/* @media screen and (min-width: 1033px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.5rem !important;
  }
} */

@media screen and (min-width: 1220px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.6rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .nav-image {
    height: 60px;
    width: 100px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 991px) {
  .nav-image {
    height: 60px;
    width: 100px;
  }

  .rem1-5-text-size {
    font-size: 1.1rem;
  }

  .rem3-4-text-size {
    font-size: 2rem !important;
  }

  .rem5-2-text-size {
    font-size: 2rem !important;
  }

  .rem3-text-size {
    font-size: 1.4rem !important;
  }

  .rem3-3-text-size {
    font-size: 1.3rem !important;
  }

  .rem2-5-text-size {
    font-size: 1.3rem !important;
  }

  .rem2-text-size {
    font-size: 1rem !important;
  }

  .rem3-6-text-size {
    font-size: 2rem !important;
  }

  .checkbox-hw {
    height: 15px;
    width: 15px;
  }
  .checkbox-font {
    font-size: 1rem;
  }

  .checkbox-big-text {
    font-size: 0.8rem !important;
  }

  .set-margin-container {
    margin-top: 0px !important;
  }

  .Home-Container {
    padding-top: 8% !important;
  }

  .home2-container-style {
    padding-top: 20%;
  }
}

.mycontent-left {
  border-right: 0.13rem solid #0388db;
}
